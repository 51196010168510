@import "abstracts/_abstracts";
.SubpageHeader {
	min-height: clamp(20rem, 35vw, 35rem);
	position: relative;
	display: flex;
	flex-flow: column;
	justify-content: center;
	margin-bottom: var(--spaceXl);

	&-image {
		color: var(--colorBrand);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		clip: rect(0, auto, auto, 0);
		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			inset: 0;
			background: linear-gradient(to right, var(--colorFooterAccentAlpha) 50%, transparent 100%);
		}

		img {
			position: absolute;
			display: block;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		img {
			object-fit: cover;
		}
	}

	&-frame {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		position: relative;
		align-items: flex-start;
		justify-content: center;
		min-height: 100%;
		flex: 1;
	}

	&-title {
		margin: 0;
		color: var(--colorThemeAccent);
		font-size: clamp(2rem, 4vw, 2.5rem);
		line-height: 1.2;
	}

	&-subTitle{
		margin-top: 2rem;
		color: var(--colorThemeAccent);
		font-size: clamp(1.5rem, 3vw, 1.75rem);
		line-height: 1;
	}
}